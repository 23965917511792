/*
 * TODO 此处引入项目logo图标、项目名称的图片
 *  public文件夹里面也要添加logo图标
 * */
import logo from '@/assets/img/base/logo.png';

let ossPath = '';
let baseUrl = '';

/*
 * TODO 需要添加或修改以下内容
 *  SIGN_KEY 签名
 * */
const SIGN_KEY = '8ku4jazkh2nkab7ccuyksuxssa3348uzsfdeupr3mr4yby2p2ptetr2wvfkxnaxc';

/*
 * TODO TODO 需要添加或修改以下内容
 *  baseUrl 后台请求地址
 *  ossPath oss图片地址
 * */
switch (import.meta.env.VITE_USER_NODE_ENV) {
  case 'pro':
    baseUrl = 'https://gw.xiaoyiqb.cn/';
    ossPath = 'https://oss.xiaoyiqb.cn/';
    break;
  case 'test':
    baseUrl = 'https://test-gw.xiaoyiqb.cn/';
    ossPath = 'https://test-oss.xiaoyiqb.cn/';
    break;
  default:
    baseUrl = 'https://test-gw.xiaoyiqb.cn/';
    ossPath = 'https://test-oss.xiaoyiqb.cn/';
}
export default { logo, SIGN_KEY, ossPath, baseUrl };

import dayjs from 'dayjs';

export function baseMethods() {
  // 微信和支付宝
  function is_weixn() {
    const ua = navigator.userAgent.toLowerCase();
    const micromessenger = ua.match(/MicroMessenger/i);
    const alipayclient = ua.match(/AlipayClient/i);
    if (micromessenger && micromessenger.length) {
      return true;
    } else if (alipayclient && alipayclient.length) {
      return true;
    } else {
      return false;
    }
  }
  function isPhone() {
    const u = navigator.userAgent,
      app = navigator.appVersion;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      //这个是安卓操作系统
      return 'Android';
    }
    if (isIOS) {
      return 'IOS';
      //这个是ios操作系统
    }
    return 'PC';
  }

  /**
   *金钱格式化
   *{param:number} s 金额
   *{param:number } n 保留几位小数
   *eg:this.fmoney(1342684.316,2) // 1,342,684.32
   */
  function formatMoney(s: string, n: number) {
    s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
    const l = s.split('.')[0].split('').reverse();
    let t = '';
    for (let index = 0; index < l.length; index++) {
      t += l[index] + ((index + 1) % 3 == 0 && index + 1 != l.length ? ',' : '');
    }
    let result = t.split('').reverse().join('');
    if (n) {
      const r = s.split('.')[1];
      result += '.' + r;
    }
    return result;
  }

  /**
   * 数字转整数 如 100000 转为10万
   * @param {需要转化的数} num
   * @param {需要保留的小数位数} point
   * @param {需要与金额一起返回单位} hasUnit
   */
  function formatMoneyUnit(num: number, point: number, hasUnit = true) {
    const numStr = num.toString();
    //大于8位数是亿
    if (numStr.length > 8) {
      const decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + point);
      if (hasUnit) {
        return parseFloat(parseInt(String(num / 100000000)) + '.' + decimal) + '亿';
      } else {
        return {
          money: parseFloat(parseInt(String(num / 100000000)) + '.' + decimal),
          unit: '亿',
        };
      }
    }
    //大于6位数是十万 (以10W分割 10W以下全部显示)
    else if (numStr.length > 4) {
      const decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point);
      if (hasUnit) {
        return parseFloat(parseInt(String(num / 10000)) + '.' + decimal) + '万';
      } else {
        return {
          money: parseFloat(parseInt(String(num / 10000)) + '.' + decimal),
          unit: '万',
        };
      }
    }
    return hasUnit ? numStr : { money: numStr, unit: '' };
  }

  /**
   * 数字转整数 如 100000 转为10万
   * @param {需要转化的数} num
   * @param {需要保留的小数位数} point
   */
  function formatMoneyNumber(num: number, point: number) {
    const numStr = num.toString();
    //大于8位数是亿
    if (numStr.length > 8) {
      const decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + point);
      return {
        money: parseFloat(parseInt(String(num / 100000000)) + '.' + decimal),
        unit: '亿',
      };
    }
    //大于6位数是十万 (以10W分割 10W以下全部显示)
    else if (numStr.length > 4) {
      const decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point);
      return {
        money: parseFloat(parseInt(String(num / 10000)) + '.' + decimal),
        unit: '万',
      };
    }
    return { money: numStr, unit: '' };
  }

  // 简单计算平均利息
  function normalInterest(money: number, month: number, rate: number) {
    return ((money * (1 + rate / 100)) / month).toFixed(2);
  }

  /** 计算所有的利息
   * 这个好像是按照等额本金来计算
   * type： 0 按日结息 1 按月结息
   * 返回值 {
   *   monthInterest：[每月利息数组]
   *   totalInterest：总利息
   *   monthBj：本月本金
   *   total： 总本金和利息之和
   *   monthRePay：本月应还本金和利息之和
   * }
   * */
  function getJDInterestOne(type: number, money: number, month: number, rate = 0.025) {
    const monthBj = money / month;

    if (type === 0) {
      // 按日计息
      const dayRate = rate;
      const dayNum: any[] = [];
      const daysArr: any[] = [];
      for (let i = 0; i < month; i++) {
        const time = dayjs().add(i, 'month').format('YYYY-MM-DD');
        dayNum.push(dayjs(time).daysInMonth());

        const times = dayjs()
          .add(i + 1, 'month')
          .format('M月DD日');
        daysArr.push(times);
      }
      const interestArr: Array<number> = [];
      const monthRePayArr: any[] = [];
      for (let i = 0; i < dayNum.length; i++) {
        const interest = (((money - monthBj * i) * dayRate) / 100) * dayNum[i];
        interestArr.push(Number(interest.toFixed(2)));
        monthRePayArr.push((interest + monthBj).toFixed(2));
      }
      const totalInterest =
        interestArr.length === 1
          ? Number(interestArr[0])
          : interestArr.reduce((a, b) => {
              return Number(a) + Number(b);
            });
      const total = <number>totalInterest + money;
      return {
        daysArr: daysArr,
        monthInterest: interestArr,
        totalInterest: Number(totalInterest).toFixed(2),
        monthBj: monthBj.toFixed(2),
        monthRePay: monthRePayArr,
        total: total.toFixed(2),
      };
    } else {
      // 按月计息
      const monthRate = rate;
      const monthInterest = (money * monthRate) / 100;
      const totalInterest = monthInterest * month;
      const total = money + totalInterest;
      const monthRePay = monthBj + monthInterest;
      return {
        daysArr: [],
        monthInterest: monthInterest.toFixed(2),
        totalInterest: totalInterest.toFixed(2),
        monthBj: monthBj.toFixed(2),
        total: total.toFixed(2),
        monthRePay: monthRePay.toFixed(2),
      };
    }
  }

  return {
    getJDInterestOne,
    normalInterest,
    formatMoneyUnit,
    formatMoneyNumber,
    formatMoney,
    is_weixn,
    isPhone,
  };
}

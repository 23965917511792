import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { wapRouter } from '@/router/wapRouter';
import { h5Router } from '@/router/h5Router';
import { guideRouter } from '@/router/guideRouter';
import { wxWapRouter } from '@/router/wxWapRouter';
import { storage } from '@/utils/storage';
import { ipAddr, userAgreementResult } from '@/api/base';
import { useUserStore } from '@/store/modules/user';

const routes: Array<RouteRecordRaw> = [
  ...wapRouter,
  ...h5Router,
  ...guideRouter,
  ...wxWapRouter,
  // 替代vue2中的'*'通配符路径
  { path: '/:pathMatch(.*)*', redirect: '/404' },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/pages/404.vue'),
  },
  {
    path: '/grxxgxsqxyUrl',
    name: '个人信息共享授权协议',
    meta: {
      title: '个人信息共享授权协议',
    },
    component: () => import('@/components/agreementInfo/grxxgxsqxy.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(), // hash 模式则使用 createWebHashHistory()
  routes,
});

router.beforeEach(async (to, from, next) => {
  storage.setItem('fromPath', from.path, null, 2);

  if (to && to.meta && to.meta.title) document.title = String(to.meta.title);
  if (to.path === '/') {
    router.go(-1);
  }

  const query = to.query;
  for (const i in query) {
    saveRouterValue(i, <string>query[i]);
  }

  if (!/404/.test(window.location.href)) {
    if (storage.getItem('localCity', 2) === null) {
      ipAddr().then((res) => {
        if (!res) return;
        storage.setItem('localCity', res.cityName, null, 2);
        if (storage.getItem('cityChoose', 2) === null) {
          storage.setItem('cityChoose', res.cityName, null, 2);
        }
      });
    }
  }

  const { setAgreementURL, agreementURLArr } = useUserStore();
  if (!agreementURLArr) {
    userAgreementResult({ packageType: 10000 }).then((res) => {
      if (res) setAgreementURL(res);
    });
  }

  next();
});
function saveRouterValue(name: string, value: string) {
  if (name) {
    if (name === 'commToken') {
      name = 'token';
    }
    if (name === 'token') {
      storage.setItem(name, value, null, 1);
    } else {
      storage.setItem(name, value, null, 2);
    }
  }
}

export default router;

import { defineStore } from 'pinia';
import api from '@/utils/http/axiosKeyConfig';
import { storage } from '@/utils';

export const mainStore = defineStore('main', {
  state: (): {
    axiosBaseURL: string;
    ossURL: string;
    urlMap: any;
    bodyCode: string;
  } => {
    return {
      axiosBaseURL: api.baseUrl,
      ossURL: api.ossPath,
      urlMap: {},
      bodyCode: storage.getItem('bodyCode', 2) || 'hxqb',
    };
  },

  getters: {},

  actions: {},
});

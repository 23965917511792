export const guideRouter = [
  {
    path: '/guideOne',
    name: 'guideOne',
    meta: {
      title: '下载页',
    },
    component: () => import('@/pages/guide/one.vue'),
  },
  {
    path: '/guideTwo',
    name: 'guideTwo',
    meta: {
      title: '公众号',
    },
    component: () => import('@/pages/guide/two.vue'),
  },
  {
    path: '/guideThree',
    name: 'guideThree',
    meta: {
      title: '机构信息',
    },
    component: () => import('@/pages/guide/three.vue'),
  },
];

import http from '@/utils/http';
import { IpTypes, ConfigQuery, AgreementResult } from './type';

// IP解析
export const ipAddr = (): Promise<IpTypes> => http.get<IpTypes>(`/customer/ipAnalysis.e`);

// 域名配置查询
export const configQuery = (data: { type: number }): Promise<ConfigQuery> =>
  http.get<ConfigQuery>(`/open/config/query.e`, data);

export const userAgreementResult = (data: { packageType: number }): Promise<AgreementResult> =>
  http.get<AgreementResult>(`/customer/userAgreementResultNew.e`, data);

export const uploadFile = (data: { file: any; ossEnum: string }): Promise<any> => {
  return http.upload<any>(`/customer/upload/image`, data);
};

// 落地页未匹配到产品触发营销
export const pageNotMatchProduct = (): Promise<any> =>
  http.get<any>(`/customer/pageNotMatchProduct`);

export interface OperateConfigType {
  id: number;
  name: string;
  type: number;
  location: number;
  sort: number;
  status: number;
  osDisplay: number;
  title: string;
  descriptor: string;
  jumpType: number;
  jumpUrl: string;
  isSplitJoint: number;
  logo: string;
  platformLimitType: number;
  platformStr: string;
  channelLimitType: number;
  channelListStr: string;
}
// 运营位配置查询
export const operateConfig = (data: {
  type: number;
  location: number;
}): Promise<OperateConfigType[]> => {
  const par = { ...data, ...{ showPlatform: '2' } };
  return http.get<OperateConfigType[]>(`/customer/operateConfig/query`, par);
};

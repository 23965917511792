export const wxWapRouter = [
  {
    path: '/homeMain',
    name: 'homeMain',
    meta: {
      title: '金融服务',
    },
    component: () => import('@/pages/wxWap/homeMain.vue'),
  },
  {
    path: '/mine',
    name: 'mine',
    meta: {
      title: '我的',
    },
    component: () => import('@/pages/wxWap/mine.vue'),
  },
  {
    path: '/applyRecord',
    name: 'applyRecord',
    meta: {
      title: '申请记录',
    },
    component: () => import('@/pages/wxWap/applyRecord.vue'),
  },
  {
    path: '/agreementList',
    name: 'agreementList',
    meta: {
      title: '用户协议',
    },
    component: () => import('@/pages/wxWap/agreementList.vue'),
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      title: '设置',
    },
    component: () => import('@/pages/wxWap/setting.vue'),
  },
];
